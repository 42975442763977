import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
// Icons
import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import { useEffect } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { FormControl } from '@mui/material';
import CustomTableRow from './CustomTableRow';


const useStyles = makeStyles(theme => ({
  root:            { width: "100%", marginTop: theme.spacing(3), overflowX: "auto" },
  table:           { minWidth: 650 },
  selectTableCell: { width: 60 },
  tableCell:       { width: 130, height: 40 },
  input:           { width: 130, height: 40 }
}));

const createData = (name, quantity, price, subtotal,) => ({
  id: name.replace(" ", "_"),
  name,
  // product,
  quantity,
  price,
  subtotal,
  isEditMode: false
});



const CustomTable = (props) => {

  console.log("props.orderItems", props?.orderItems);
  console.log("props.products", props?.products);

  const [rows, setRows] = useState(props?.orderItems);
  // const [orderItems, setOrderItems] = useState(props?.orderItems);
  const [products, setProducts] = useState(props?.products);
  const [newOrderItem, setNewOrderItem] = useState("");
  console.log("rows", rows);
  const classes = useStyles();
  
  // useEffect(() => {
  //   setRows(rows?.map(orderItem => ({ ...orderItem, isEditMode: false })));
  //   console.log("useEffect ran");
  // }, [props.orderItems]);

  const isEditing = rows?.some(row => row.isEditMode === true);
  console.log("isEditing", isEditing);

  // useEffect(() => {
  //   if(isEditing) {
  //     setRows((prev) => [...prev]);
  //   }
  // }, [isEditing])

  console.log(props?.products);
  console.log(newOrderItem);
  console.log("rows", rows);

  const AddNewProduct = () => {
    return (
      <FormControl fullWidth>
        <Select
          inputProps={{ 'aria-label': 'Without label' }}
          id="product-select"
          value={newOrderItem}
          label="Select Product"
          onChange={(e) => setNewOrderItem(e.target.value)}
        >
          {props?.products?.map(product => {
            return (
              <MenuItem value={product.id} key={product.id}>{product.title} - {product.variant}</MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  useEffect(() => {
    if(newOrderItem) {
      let newItem = props?.products?.filter(p => p.id === newOrderItem)[0];
      setRows((prev) => [...prev, { ...newItem, isEditMode: false, quantity: 1, total: newItem.price } ])
    }
  }, [newOrderItem, props?.products])

  

  // const onChange = (e, row) => {
  //   console.log("event", e);
  //   console.log("onChange row", row);
  //   if (!previous[row.id]) {
  //     setPrevious(state => ({ ...state, [row.id]: row }));
  //   }
  //   const value = e.target.value;
  //   const name = e.target.name;
  //   const { id } = row;
  //   const newRows = rows.map(row => {
  //     if (row.id === id) {
  //       console.log("row", row);
  //       if(row.quantity === 0){
  //         return null;
  //       } else return { ...row, [name]: value };
  //     }
  //     return row;
  //   });
  //   setRows(newRows);
  // };
  


  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="caption table">
        {/* <caption>Orders Table</caption> */}
        <TableHead>
          <TableRow>
            <TableCell align="left" />
            <TableCell align="left">Product</TableCell>
            <TableCell align="left">Quantity</TableCell>
            <TableCell align="left">Price</TableCell>
            <TableCell align="left">Subtotal</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <CustomTableRow row={row} rows={rows} products={props?.products} setRows={setRows} />
          ))}
        </TableBody>
      </Table>
      <AddNewProduct />
    </Paper>
  );
}

export default CustomTable;
