import { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Input, MenuItem, Select, TableCell, TableRow } from '@mui/material';
import { Delete, Done, Edit, NotInterestedOutlined } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
  root: { width: "100%", marginTop: theme.spacing(3), overflowX: "auto" },
  table: { minWidth: 650 },
  selectTableCell: { width: 250 },
  tableCell: { width: 80, height: 40 },
  productName: { width: 250 },
  input: { width: 80, height: 40 },
  actionBtns: { width: 30 },
}));


const CustomTableRow = ({ row, rows, products, setRows }) => {
  // console.log("props.row", row);
  // console.log("name", name);
  // console.log("products", products);
  const classes = useStyles();

  const [rowData, setRowData] = useState(row);
  const [isEditing, setIsEditing] = useState(false);
  const [previous, setPrevious] = useState(row);
  const [cancel, setCancel] = useState(false);

  const [newProduct, setNewProduct] = useState(null);

  const [price, setPrice] = useState(row.price);
  const [quantity, setQuantity] = useState(row.quantity);
  const [total, setTotal] = useState(row.total);

  const toggleEditMode = () => setIsEditing(!isEditing);

  const revert = () => {
    setCancel(true);
    // toggleEditMode();
    setIsEditing(false);
    setRowData(previous);
    // setQuantity(previous.quantity);
    // setPrice(previous.price);
    // setTotal(previous.total);
  };

  const onChangeQty = (e) => {
    if (cancel) {
      revert();
    }
    else {
      // setQuantity(parseInt(e.target.value));
      setRowData((prev) => ({ ...prev, quantity: parseInt(e.target.value) || 0 }));
    }
  };

  console.log("rowData", rowData);
  console.log("newProduct", newProduct);

  const onChangePrice = (e) => {
    if (cancel) {
      revert();
    } else {
      // setPrice(parseInt(e.target.value));
      setRowData((prev) => ({ ...prev, price: parseInt(e.target.value) || 0 }));
    }
  };

  const onChangeTotal = (e) => {
    setTotal(e.target.value);
    if (cancel) {
      revert();
    } else {
      // setTotal(parseInt(e.target.value));
      setRowData((prev) => ({ ...prev, total: parseInt(e.target.value) }));
    }
  };

  
  const onChangeSelectMenu = (e) => {

    console.log("isEditing", isEditing);
    if (products?.length) {
      // const oldProduct = props?.products?.filter(product => product.id === row.product_id)[0];
      setNewProduct(products?.filter(product => product.id === e.target.value)[0]);
      console.log("products:", products);
      console.log("newProduct:", newProduct);
      console.log("row being edited", row);

      if (cancel) {
        revert();
      }
    }
  };

  useEffect(() => {
    console.log("newProduct", newProduct);
    if(newProduct?.id && newProduct?.title)
    setRowData((prev) => ({ ...prev, quantity: 1, price: newProduct?.price, product_id: newProduct?.id, title: newProduct?.title, variant: newProduct?.variant, thumbnail: newProduct?.thumbnail, total: newProduct?.total }));
  }, [newProduct?.id, newProduct])

  useEffect(() => {
    setRowData((prev) => {
      return { ...prev, total: rowData?.price * rowData?.quantity }
    })
  }, [rowData?.price, rowData?.quantity])

  useEffect(() => {
    setRows(() => rows?.map((r) => {
      if (r.id === row.id) {
        return ({ ...r, quantity: 1, price: newProduct?.price, product_id: newProduct?.id, title: newProduct?.title, variant: newProduct?.variant, thumbnail: newProduct?.thumbnail, total: newProduct?.total })
      } else return r;
    }))
  }, [newProduct, rowData])



  return (

    <TableRow key={row.id}>
      <TableCell className={classes.actionBtns}>
        {isEditing ? (
          <>
            <IconButton aria-label="done" onClick={() => setIsEditing(false)}>
              <Done />
            </IconButton>
            <IconButton aria-label="revert" onClick={() => revert()}>
              <NotInterestedOutlined />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton aria-label="edit" onClick={() => setIsEditing(true)}>
              <Edit />
            </IconButton>
            <IconButton aria-label="delete" onClick={(e) => { setRows(rows?.filter(r => r.id !== rowData?.id)) }}>
              <Delete />
            </IconButton>
          </>
        )}
      </TableCell>

      <TableCell align="left" className={classes.productName}>

        {isEditing ? (
          <Select
            inputProps={{ 'aria-label': 'Without label' }}
            id="product-select"
            // name={name}
            value={rowData.product_id}
            label="Select Product"
            className={classes.selectTableCell}
            onChange={e => onChangeSelectMenu(e)}
          >
            {products?.map(product => {
              // console.log("products val:", `${product.title} - ${product.variant}`);
              return (
                <MenuItem value={product.id} key={product.id}>{product.title} - {product.variant}</MenuItem>
              );
            })}
          </Select>
        ) : `${rowData.title} - ${rowData.variant}`}
      </TableCell>

      <TableCell align="left" className={classes.tableCell}>

        {isEditing ? (
          <Input
            value={rowData?.quantity}
            name={rowData?.quantity}
            onChange={e => onChangeQty(e)}
            className={classes.input}
          />
        ) : rowData?.quantity}
      </TableCell>

      <TableCell align="left" className={classes.tableCell}>

        {isEditing ? (
          <Input
            value={rowData.price}
            name={rowData.price}
            onChange={e => onChangePrice(e)}
            className={classes.input}
          />
        ) : rowData?.price}
      </TableCell>

      <TableCell align="left" className={classes.tableCell}>

        {isEditing ? (
          <Input
            value={rowData.total}
            name={rowData.total}
            onChange={e => onChangeTotal(e)}
            className={classes.input}
          />
        ) : rowData?.total}


      </TableCell>


    </TableRow>


  );
};

export default CustomTableRow;
