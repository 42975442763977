import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import EqualizerIcon from '@material-ui/icons/Equalizer';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AssessmentIcon from '@material-ui/icons/Assessment'; 
import TranslateIcon from '@material-ui/icons/Translate';
import ExploreIcon from '@material-ui/icons/Explore';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import GroupWorkIcon from '@material-ui/icons//GroupWork';
import StoreIcon from '@material-ui/icons/Store';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import CouponIcon from '@mui/icons-material/ConfirmationNumber';

import { Link } from "react-router-dom";

import Logo from '../assets/img/nav-logo.png';

import AuthContext from '../store/AuthContext.js';



import '../App.scss';

const noSideBarRoutes = ["/login", "/register"];

function SideBar() {

	const authCtx 								= useContext(AuthContext);
	const { pathname } 						= useLocation();
	
	const isLoggedIn 							= authCtx.isLoggedIn;
	
	
	if (noSideBarRoutes.some((item) => pathname.includes(item))) return null;

	return (
		<div className="menu">
			<div className="company-logo"><img src={Logo} /></div>
			<nav>
				{!isLoggedIn && (<Link to="/login"><EqualizerIcon />Login</Link>)}
				{isLoggedIn && (<Link to="/overview"><EqualizerIcon />Overview</Link>)}
				{isLoggedIn && (<Link to="/customers"><PeopleAltIcon />Customers</Link>)}
				{isLoggedIn && (<Link to="/orders"><ShoppingBasketIcon />Orders</Link>)}
				{isLoggedIn && (<Link to="/retailers"><StoreIcon />Retailers</Link>)}
				{isLoggedIn && (<Link to="/products"><AssessmentIcon />Products</Link>)}
				{isLoggedIn && (<Link to="/coupons"><CouponIcon />Coupons</Link>)}
				{isLoggedIn && (<a onClick={() => authCtx.logout()}><ExitToAppIcon />Logout</a>)}
			</nav>
			
		</div>
	);
}
export default SideBar;