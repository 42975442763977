import { createContext, useState } from 'react';

const DrawerContext = createContext({
  open: false,
  drawer: 'none',
  details: {},
  pageUpdate: {},
  setDetails: (setDetails) => {},
  setPageUpdate: (setPageUpdate) => {},
  openDrawer: (openDrawer) => {},
  closeDrawer: (closeDrawer) => {}
});

export function DrawerContextProvider(props) {

  const [open, setOpen] = useState(false);
  const [drawer, setDrawer] = useState('none');
  const [details, setDetails] = useState({});
  const [pageUpdate, setPageUpdate] = useState({});

  function openDrawerHandler() {
    setOpen(true);
  }

  function closeDrawerHandler() {
    setOpen(false);
  }

  function setDetailsHandler(drawer, data) {
    setDrawer(drawer);
    setDetails(data);
  }

  function setPageUpdateHandler(data) {
    console.log(data);
    setPageUpdate(data);
  }

  const context = {
    open: open,
    drawer: drawer,
    details: details,
    pageUpdate: pageUpdate,
    setDetails: setDetailsHandler,
    setPageUpdate: setPageUpdateHandler,
    openDrawer: openDrawerHandler,
    closeDrawer: closeDrawerHandler
  };

  return <DrawerContext.Provider value={context}>
    {props.children}
  </DrawerContext.Provider>
}

export default DrawerContext;