import React, { useContext, useState, useEffect } from 'react';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';

import AuthContext from '../store/AuthContext.js';
import DrawerContext from '../store/DrawerContext.js';

import NoImage from '../assets/img/no-image.gif';
import { Button } from '@material-ui/core';

const ProductsGrid = () => {

  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);

  const [products, setProducts] = useState([]);

	const breakpoints = {
		xs: 0,
		sm: 600,
		md: 960,
		lg: 1280,
		xl: 1920
	}

	const getColumns = (width) => {
		if (width < breakpoints.sm) {
			return 1
		} else if (width < breakpoints.md) {
			return 2
		} else if (width < breakpoints.lg) {
			return 3
		} else if (width < breakpoints.xl) {
			return 4
		} else {
			return 5
		}
	}

	const [columns, setColumns] = useState(getColumns(window.innerWidth));
  const [isLoading, setIsLoading] = useState(true);
	
	useEffect(() => {
    getProducts();
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	function toggleDrawerHandler(drawer, user) {
		drawerCtx.openDrawer();
		drawerCtx.setDetails(drawer, user);
	};

	const updateDimensions = () => {
		setColumns(getColumns(window.innerWidth))
	}

  const getProducts = () => {
    
    fetch(process.env.REACT_APP_API_URI + '/v1/products', {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if(data.status == "success") {
        setProducts(data.products);
        setIsLoading(false);
        // setNetworkPaymentFee(data.network[0].payment_fee);
        // if(data.network[0].payment_recurring != "none") {
        //   setNetworkPaymentRecurring(data.network[0].payment_recurring);
        // } else {
        //   setNetworkPaymentRecurring("once off");
        // }
        // setUserEmail(data.user[0].email);
        // getImg(data.network[0].cover);
        // getIntent();
        // // navigate("/results/" + id, { replace: true });
      } else {
        // setOpenSnackbar(true);
      }
    });

  }

  if(drawerCtx.pageUpdate.page == "products") {
    drawerCtx.setPageUpdate({});
    getProducts();
  };

  const uploadImage = (id) => {
    const currentProduct = products?.find((prod) => prod.id === id);
    // console.log("currentProduct", currentProduct);
    drawerCtx.openDrawer();
		drawerCtx.setDetails("product-image-upload", currentProduct);
  }

  if (isLoading) {
		return <section><CircularProgress /></section>
	}

  const hoverUploadImgText = (itemID) => {
    return (
      <div className="hover-div" onClick={() => uploadImage(itemID)}>
        <p className="hover-text">Upload Image</p>
      </div>)
  }

  return (
    <ImageList cols={columns}>
      {products.map((item) => (
        <ImageListItem className="product-container" key={item.id} >
          { item.image ? 
            <img className="hover-img" onClick={() => uploadImage(item.id)} src={item.image} srcSet={item.image} alt={item.title} loading="lazy" /> :
            <img className="hover-img" onClick={() => uploadImage(item.id)} src={NoImage} srcSet={NoImage} alt={item.title} loading="lazy" />
          }
          {hoverUploadImgText(item.id)}
          
          <div className="product-btn-container">
            <ImageListItemBar sx={{textAlign: 'center'}} title={item.title} subtitle={<span>{item.variant}</span>} position="below" />
            {/*<Button onClick={(e) => {
              toggleDrawerHandler("edit-product", item);
              console.log(item);
            }}>Edit</Button>*/}
          </div>
          <button className="top-right-btn" onClick={(e) => {
              toggleDrawerHandler("edit-product", item);
              console.log(item);
            }}><EditIcon /></button>
        </ImageListItem>
      ))}
    </ImageList>
  );


}
export default ProductsGrid;