import { useState, useContext, useEffect } from "react";
import axios from 'axios';
import CreatableSelect from 'react-select/creatable';

import { ToggleButton, ToggleButtonGroup, Button, CircularProgress } from "@mui/material";

import AuthContext from '../../store/AuthContext.js';
import ModalContext from '../../store/ModalContext.js';

const CreateOrderModal = (props) => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const [currentCreateOptions, setCurrentCreateOptions] = useState("loading");
  
  const [saleType, setSaleType]               = useState("");
  const [updateLists, setUpdateLists]         = useState(false);
  const [customersSelect, setCustomersSelect] = useState([]);
  const [retailersSelect, setRetailersSelect] = useState([]);

  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedRetailer, setSelectedRetailer] = useState("");
  const [isLoading, setIsLoading]               = useState(false);

  console.log("saleType", saleType);

  useEffect(() => {

    let options = {
      method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }

		Promise.all([
      fetch(process.env.REACT_APP_API_URI + '/v1/customers/', options).then(response => response.json()),
      fetch(process.env.REACT_APP_API_URI + '/v1/retailers/', options).then(response => response.json())
    ]).then((results) => {
      console.log(results);
      if (results[0].status == "success" && results[1].status == "success") {
        
        if (results[0].details.length > 0) {
          setCustomersSelect(results[0].details.map((customer) => {
            return { ...customer, label: customer?.full_name || "Empty!", value: customer?.full_name || "Empty!" }
          }))
        }

        if (results[1].details.length > 0) {
          setRetailersSelect(results[1].details.map((retailer) => {
            return { ...retailer, label: retailer?.retailer, value: retailer?.retailer }
          }))
        }
        
        setSaleType("");
        setCurrentCreateOptions("selectType");
      }
    }).catch((err) => {
			// setOpenSnackbar(true);
      console.log(err);
    });
    
  }, [updateLists]);

  const createCustomer = (inputValue) => {
    let jsonBody = JSON.stringify({ customerName: inputValue });

    fetch(process.env.REACT_APP_API_URI + '/v1/customers', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: jsonBody,
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log("data", data);
      if (data.status === "success") {
        setUpdateLists(!updateLists);
        console.log("successfully created new customer!");
      } else {
        console.log("failed to create new customer");
      }
    }).catch(err => console.error(err, "failed to create new customer"));

  }

  const createRetailer = (inputValue) => {
    let jsonBody = JSON.stringify({ retailerName: inputValue });

    fetch(process.env.REACT_APP_API_URI + '/v1/retailers', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: jsonBody,
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log("data", data);
      if (data.status === "success") {
        setUpdateLists(!updateLists);
        console.log("successfully created new retailer!");
      } else {
        console.log("failed to create new retailer");
      }
    }).catch(err => console.error(err, "failed to create new retailer"));
  }

  const createOrder = () => {

    let payload;
    if(saleType === "customer") {
      payload = {
        orderType: "customer",
        id: selectedCustomer.id
      }
    } else if (saleType === "retailer") {
      payload = {
        orderType: "retailer",
        id: selectedRetailer.id
      }
    }

    setIsLoading(true);
    
    fetch(process.env.REACT_APP_API_URI + '/v1/orders', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log("data", data);
      if (data.status === "success") {
        // handleCloseModal();
        setIsLoading(false);
      } else {
        // console.log("failed to create new retailer");
        setIsLoading(false);
      }
    }).catch(err => {
      console.error(err, "failed to create new order");
      setIsLoading(false);
    });
  }

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  const updateCustomerOrderType = (event, newSaleType) => {
    if(newSaleType === "customer") {
      setSaleType("customer");
      setCurrentCreateOptions("selectCustomer");
    } else if(newSaleType === "retailer") {
      setSaleType("retailer");
      setCurrentCreateOptions("selectRetailer");
    } else {
      setSaleType("");
    }
  };

  const showCreateOptions = () => {
    switch(currentCreateOptions) {

      case "loading": return <>
        <div className="loading"><CircularProgress /></div>
        <div className="footer">
          <Button type="submit" className="next-form-btn cancel" onClick={() => handleCloseModal()}>Cancel</Button>
        </div>
      </>;

      case "selectType": return <>
        <div className="order-type">
          <p>Create order for retailer or customer?</p>

          <ToggleButtonGroup color="primary" value={saleType} exclusive onChange={updateCustomerOrderType} aria-label="Platform">
            <ToggleButton value="customer">Customer</ToggleButton>
            <ToggleButton value="retailer">Retailer</ToggleButton>
          </ToggleButtonGroup>
          <br/><br/>
        </div>
        <div className="footer">
          <Button type="submit" className="next-form-btn cancel" onClick={() => handleCloseModal()}>Cancel</Button>
        </div>
      </>;

      case "selectCustomer": return <>
        <div className="selection">
          <p>Select/Add the customer for this order</p>

          <CreatableSelect isClearable onChange={(newValue) => setSelectedCustomer(newValue)} onCreateOption={createCustomer} options={customersSelect} />
          <br/><br/>

        </div>
        <div className="footer">
          <Button type="submit" className="next-form-btn cancel" onClick={() => {
            setSaleType("");
            setCurrentCreateOptions("selectType")
          }}>Back</Button>
          <Button type="submit" className="next-form-btn" onClick={() => createOrder()}>{ isLoading ? <CircularProgress style={{ color: "#dedede" }} /> : "Create Order"}</Button>
        </div>
      </>;

      case "selectRetailer": return <>
        <div className="selection">
          <p>Select/Add the retailer for this order</p>

          <CreatableSelect isClearable onChange={(newValue) => setSelectedCustomer(newValue)} onCreateOption={createRetailer} options={retailersSelect} />
          <br/><br/>

        </div>
        <div className="footer">
          <Button type="submit" className="next-form-btn cancel" onClick={() => {
            setSaleType("");
            setCurrentCreateOptions("selectType")
          }}>Back</Button>
          <Button type="submit" className="next-form-btn" onClick={() => createOrder()}>Create Order</Button>
        </div>
      </>;

      default: return null;

    }
  }


  return (
    <div className="modal">
      <header><h2>Create New Order</h2></header>
      {showCreateOptions()}
    </div>
  );
}

export default CreateOrderModal;

