import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';

import { TextField, MenuItem, FormControl, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import AuthContext from '../../store/AuthContext.js';
import DrawerContext from '../../store/DrawerContext.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function EditProductDrawer(props) {

  console.log("editProductDrawer", props?.productDetails);
  const editProduct = props?.productDetails;

  const authCtx = useContext(AuthContext);	
  const drawerCtx = useContext(DrawerContext);

  // const [products, setProducts] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const [parentProduct, setParentProduct] = useState(-1);
  const [parentProducts, setParentProducts] = useState([]);
  const [showForm, setShowForm] = useState("");

  const [productTitle, setProductTitle] = useState("");
  const [productVariant, setProductVariant] = useState("");
  const [productPrice, setProductPrice] = useState(0.00);
  const [productDescription, setProductDescription] = useState("");


  useEffect(() => {
    fetch(process.env.REACT_APP_API_URI + '/v1/products/byParent', {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }).then(response => {
      return response.json();
    }).then(data => {
			console.log(data);
      if(data.status == "success") {
        setParentProducts(data.products);
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
      }
    });
	}, []);

  useEffect(() => {
    setProductDescription(editProduct.description);
    setProductTitle(editProduct.title);
    setProductVariant(editProduct.variant);
    setProductPrice(editProduct.price);
    setParentProduct(editProduct.parent_id);

  }, [editProduct])

  console.log(parentProduct);  

  const updateBaseProduct = (parent) => {
    setParentProduct(parent);
    if(parent == 0) {
      setShowForm("parent");
    } else {
      setShowForm("variant");
    }
  }

  const checkBtn = () => {
    if(productVariant != "" && productPrice != "" && productDescription != "") {
      return false;
    } else {
      return true;
    }
  }

  const editProductFunc = (event) => {
    event.preventDefault();

    setDisabled(true);
    setIsLoading(true);
    
    let title;
    if(parentProduct == 0) {
      title             = productTitle;
    } else {
      let product       = parentProducts.find(function (obj) { return obj.id == parentProduct});
      if(product) {
        title           = product.title;
      }
    }

    let jsonBody        = JSON.stringify({
      parentId: parentProduct,
      title: title,
      variant: productVariant,
      price: productPrice,
      description: productDescription,
      id: editProduct?.id,    
    });

    console.log("jsonBody", jsonBody);

    fetch(process.env.REACT_APP_API_URI + '/v1/products/' + editProduct.id, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: jsonBody,
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if(data.status == "success") {
        drawerCtx.closeDrawer();
        drawerCtx.setPageUpdate({page: "products"});
      } else {
        setDisabled(false);
        setIsLoading(false);
      }
    });

  }



  const renderForm = () => {
    
    return <>
      { showForm == "parent" ? <div className="double-column-form">
        <TextField label="Title" value={productTitle} onChange={(e) => {setProductTitle(e.target.value)}} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
      </div> : null }
      <br/>
      <div className="double-column-form">
        <TextField label="Specification" value={productVariant} onChange={(e) => {setProductVariant(e.target.value)}} placeholder="Specification" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
        <TextField label="Price" value={productPrice} onChange={(e) => {setProductPrice(e.target.value)}} placeholder="Price" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
      </div>
      <br/>
      <div className="double-column-form">
        <TextField multiline rows={4} label="Description" value={productDescription} onChange={(e) => {setProductDescription(e.target.value)}} placeholder="Description" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
      </div>
      <br/>
    </>;
  }
  
  // console.log(editProduct.parent_id, editProduct.id, parentProduct, parentProducts);

  return (
    <div className="container">
      <div className="drawer">

        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Edit Product { isLoading ? <CircularProgress size={25} /> : null }</h1>
        </header>

        <section>

          <div className="double-column-form">
            <FormControl fullWidth>
              {/* <InputLabel id='base-product'>Base Product</InputLabel> */}
              <TextField
                value={parentProduct}
                onChange={(e) => updateBaseProduct(e.target.value)}
                select // tell TextField to render select
                label="Base Product"
                variant="outlined"
              >
              {/* <Select labelId="base-product" label="Base Product" value={parentProduct} onChange={(e) => {updateFileType(e)}} placeholder="File Type" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required> */}
                <MenuItem value='-1'>Choose 'None' for base product</MenuItem>
                <MenuItem value='0'>None (New base product)</MenuItem>
                { parentProducts.map((data) => (
                  <MenuItem value={data.id} key={data.id}>{data.title} ({data.variant})</MenuItem>
                ))}
              </TextField>
              {/* </Select>  */}
            </FormControl>
          </div>
          <br/>

          { renderForm() }

          <div className="double-column-form aligned-right margin-top">
            
            <Button variant="contained" component="label" disabled={checkBtn() || disabled}>
              Edit Product
              <input type="file" onClick={(e) => editProductFunc(e)} hidden />
            </Button>
          </div>

        </section>
        
      </div>
    </div>
  );

}
export default EditProductDrawer;

