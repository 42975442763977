import { useContext, useState, useEffect } from 'react';
import AOS from 'aos';

import { DataGrid } from '@mui/x-data-grid';
import { Link } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';

import AuthContext from '../store/AuthContext.js';
import DrawerContext from '../store/DrawerContext.js';
import ModalContext from '../store/ModalContext.js';

// import ClientsTable from "../Components/ClientsTable";

// ..
AOS.init();

const Coupons = () => {
	
	
	const authCtx = useContext(AuthContext);	
  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);

	const [coupons, setCoupons] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {

		setIsLoading(true);
		fetch(process.env.REACT_APP_API_URI + '/v1/coupons', {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
		  }).then(response => {
			return response.json();
		  }).then(data => {
				  console.log(data);
			if(data.status == "success") {
			  setCoupons(data.details);
			  setIsLoading(false);
			} else {
			  // setOpenSnackbar(true);
			}
		  });

	}, []);
  
	function toggleDrawerHandler(drawer, user) {
		drawerCtx.openDrawer();
		drawerCtx.setDetails(drawer, user);
	};

	function toggleModalHandler(modal, user) {
		modalCtx.openModal();
		modalCtx.setDetails(modal, user);
	};

	const getDateISO = (createdAt) => new Date(createdAt.replace(/ /g,"T")).toISOString();

	const getDateString = (dateISOString) => {
    const dateArr = new Date(dateISOString)?.toDateString()?.split(" ")?.slice(1, 4);
    const dateStr = dateArr[0] + " " + dateArr[1] + ", " + dateArr[2].slice(2, 4);
    return dateStr || "";
  }
	
	const columns = [
		{ field: 'id', headerName: 'ID', width: 100, headerAlign: "center", align: "center", renderCell: (params) => (<Link className="order-details-link" onClick={() => {
			// console.log("order params.row\n", params.row);
			toggleDrawerHandler('edit-coupon', { orderDetails: params?.row });
		}}>{params.value?.toString()?.padStart(4, "0") || params.value}</Link>) },
		// { renderCell: (params) => <Link to ></Link>, headerName: 'ID', width: 70 },
		{ field: 'date', headerName: 'DATE', width: 180, headerAlign: "center", align: "center", renderCell: ({ row }) => getDateString(row.date)},
		{ field: 'customer', headerName: 'COUPON CODE', width: 200, renderCell: (params) => (<Link className="customer-details-link" onClick={() => {
			toggleDrawerHandler('customer-details', { customerDetails: params?.row });
		}}>{params.value}</Link>) },
		{ field: 'desc', headerName: 'DESCRIPTION', width: 200 },
		{ field: 'auto_apply', headerName: 'AUTO APPLY', width: 100 },
		{ field: 'type', headerName: 'TYPE', width: 100 },
		{ field: 'valid', headerName: 'VALID TILL', width: 150, renderCell: ({ row }) => getDateString(row.valid)},
		{ field: 'total', headerName: 'DISCOUNT', width: 150 },
		{ field: 'discount_type', headerName: 'DISCOUNT TYPE', width: 200},
		{ field: 'usage', headerName: 'USAGE', width: 100},
		{ field: 'del', headerName: '', width: 50, renderCell: (params) => (<Link className="customer-details-link" onClick={() => {
			toggleDrawerHandler('delete-coupon', { orderDetails: params?.row });
		}}><DeleteIcon /></Link>) },
	];

	const rowsData = coupons?.map(coupon => {
		return coupon.id && ({ id: coupon.id, customer_id: coupon.customer_id, claimed: coupon?.claimed, desc: coupon?.description, usage: coupon?.usage_type, date: coupon?.created_at, discount_type: coupon?.discount_type, valid: coupon?.valid_till, type: coupon?.coupon_type, auto_apply: coupon?.auto_apply, customer: coupon?.coupon_code, total: coupon?.discount, order: coupon?.discount});
	})

	console.log("rowsData", rowsData);
  

	return (
		<div className="container">
			<header>
				<div data-aos="fade-up">
					<h1>Coupons</h1>
				</div>
				<div data-aos="fade-up" data-aos-delay="400">
					<a className="header-cta" onClick={() => {toggleDrawerHandler('create-coupon', null)}}>Create</a>
				</div>
			</header>			
			{/* <ClientsTable /> */}
			<div data-aos="fade-up" style={{ height: 500, width: '100%' }}>
      	<DataGrid sx={{ boxShadow: 2, border: 1, borderColor: '#77777777',
					'& .MuiDataGrid-cell:hover': { color: 'primary.main', }, }} 
					rows={rowsData} columns={columns} />
    </div>
		</div>
	);

}
export default Coupons;