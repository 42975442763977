import React from 'react';
import '../App.scss';
import { Typography, Paper, CardContent, Grid, TextField, Button } from '@material-ui/core'
import { SyntheticEvent, useState } from 'react';
import { useNavigate } from "react-router-dom";


const Register = () => {

	let navigate        					= useNavigate();

	const [firstName, setfName] = useState('');
	const [lastName, setlName] = useState('');
	const [emailOrPhone, setEmailOrPhone] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [cPassword, setcPassword] = useState('');
	const [redirect, setRedirect] = useState(false);

	const submit = () => {
		
		fetch(process.env.REACT_APP_API_URI + '/v2/register', {
			method: 'POST',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				email,
				password
			})
		}).then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			if(data.status == "success") {
				setRedirect(true);
			} else {
				// setSnackbarMessage(data.message);
				// setOpenSnackbar(true);
			}
		});

			// await fetch('http://localhost:3001/v1/register', {
			// 		method: 'POST',
			// 		headers: { 'Content-Type': 'application/json' },
			// 		body: JSON.stringify({
			// 				firstName,
			// 				lastName,
			// 				emailOrPhone,
			// 				password,
			// 				cPassword,
			// 		})
			// });

			// setRedirect(true);
	}

	if (redirect) {
		navigate("/overview", { replace: true });
	}







    const paperStyle = { padding: 20, height: '70vh', width: 580, margin: "20px auto" }
    return (
        <div className="smiler-register">
            <div className="register-left">
                <div className="register-wrapper">


                    <Paper elevation={10} style={paperStyle}>
                        <CardContent>

                            <div className="form-heading">Register for free</div>
                            <div className="form-wrapper">

                                <form onSubmit={submit}>



                                    <Grid container spacing={4}>
                                        <Grid xs={12} sm={6} item>
                                            <div className="field-label">First name</div>
                                            <TextField label="First Name" placeholder="First Name"  onChange={e => setfName(e.target.value)} variant="outlined" fullWidth required />
                                        </Grid>




                                        <Grid xs={12} sm={6} item>
                                            <div className="field-label">Last name</div>
                                            <TextField label="Last Name" placeholder="Last Name"  onChange={e => setlName(e.target.value)} variant="outlined" fullWidth required />
                                        </Grid>
                                        <Grid xs={12} item>
                                            <div className="field-label">Email or Mobile Number</div>
                                            <TextField label="Email or mobile number"   onChange={e => setEmailOrPhone(e.target.value)} placeholder="Email Address/Mobile Number" variant="outlined" fullWidth required />
                                        </Grid>
                                        <Grid xs={12} item>
                                            <div className="field-label">Password</div>
                                            <TextField label="Password" placeholder="Password"   onChange={e => setPassword(e.target.value)} variant="outlined" fullWidth required />
                                        </Grid>
                                        <Grid xs={12} item>
                                            <div className="field-label">Confirm password</div>
                                            <TextField label="Confirm Password" onChange={e => setcPassword(e.target.value)} placeholder="Confirm Password" variant="outlined" fullWidth required />
                                        </Grid>

                                        <Button mat-raised-button variant="contained" type="submit" color="yellow" className="yellowBtn btn-large">Register</Button>


                                    </Grid>

                                </form>
                            </div>


                        </CardContent>
                    </Paper>
                </div>
            </div>
        </div>

    );
}
export default Register;