import React from 'react';

import CustomersTable from "../Components/CustomersTable";
import '../App.scss';
// import { AnimationOnScroll } from 'react-animation-on-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
AOS.init();


// import '../App.scss';

const Customers = () => {

	return (
		<div className="container">
			<div data-aos="fade-up">
				<h1>Customers</h1>
			</div>
			<CustomersTable />
		</div>
	);

}
export default Customers;