import { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';

import { TextField, Select, MenuItem, FormControl, InputLabel, Button, Checkbox } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import AuthContext from '../../store/AuthContext.js';
import DrawerContext from '../../store/DrawerContext.js';
import { Add } from '@material-ui/icons';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function UploadProductImageDrawer({ details }) {

  const authCtx = useContext(AuthContext);	
  const drawerCtx = useContext(DrawerContext);

  console.log("props", details);
  const { id } = details;

  const [selectedTab, setSelectedTab] = useState(0);
  
  const [isZip, setIsZip] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const [parentProduct, setParentProduct]   = useState(-1);
  const [parentProducts, setParentProducts] = useState([]);
  const [productImages, setProductImages]   = useState([]);
  const [additionalImgs, setAdditionalImgs] = useState(details?.additionalImages);
  const [additionalImgsFilelist, setAdditionalImgsFilelist] = useState();
  const [showForm, setShowForm]             = useState("");

  const [productTitle, setProductTitle]     = useState("");
  const [productVariant, setProductVariant] = useState("");
  const [productPrice, setProductPrice]     = useState(0.00);
  const [productDescription, setProductDescription] = useState("");

  const updateBaseProduct = (parent) => {
    setParentProduct(parent);
    if(parent == 0) {
      setShowForm("parent");
    } else if(parent == -1) {
      setShowForm("");
    } else {
      setShowForm("variant");
    }
  }

  useEffect(() => {
    setProductImages(additionalImgs);
    setIsLoading(false);
    setDisabled(false);
    // fetch(process.env.REACT_APP_API_URI + '/v1/products/byParent', {
    //   method: 'GET',
    //   mode: 'cors',
    //   headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    // }).then(response => {
    //   return response.json();
    // }).then(data => {
		// 	console.log(data);
    //   if(data.status == "success") {
    //     setParentProducts(data.products);
    //     setIsLoading(false);
    //     // setNetworkPaymentFee(data.network[0].payment_fee);
    //     // if(data.network[0].payment_recurring != "none") {
    //     //   setNetworkPaymentRecurring(data.network[0].payment_recurring);
    //     // } else {
    //     //   setNetworkPaymentRecurring("once off");
    //     // }
    //     // setUserEmail(data.user[0].email);
    //     // getImg(data.network[0].cover);
    //     // getIntent();
    //     // // navigate("/results/" + id, { replace: true });
    //   } else {
    //     // setOpenSnackbar(true);
    //   }
    // });

    // fetch(process.env.REACT_APP_API_URI + '/v1/products-images/' + id, {
    //   method: 'GET',
    //   mode: 'cors',
    //   headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    // }).then(response => {
    //   return response.json();
    // }).then(data => {
    //   setProductImages(data.sort((p1, p2) => (p1.updated_at < p2.updated_at) ? 1 : (p1.updated_at > p2.updated_at) ? -1 : 0));
    //   if(data.status == "success") {
    //     setIsLoading(false);
    //   }
    // });
	}, []);

  console.log(productImages);


  /* 
  let sortedDates = products.sort((p1, p2) => (p1.manufactured > p2.manufactured) ? 1 : (p1.manufactured < p2.manufactured) ? -1 : 0);
  console.log("Products sorted based on ascending order of their manufacture dates are:")
  console.log(sortedDates);

   */
  
  const onFileChangeCapture = (event) => {
    console.log(isZip);
    console.log("here");
    /*Selected files data can be collected here.*/
    console.log(event.target.files[0].name);    
    // props.snackbarShowMessage("Uploading zip file... please wait");
  };

  const checkBtn = () => {
    if(productVariant != "" && productPrice != "" && productDescription != "") {
      return false;
    } else {
      return true;
    }
  }

  const uploadImage = (event) => {
    event.preventDefault();

    setDisabled(true);
    setIsLoading(true);

    let formdata = new FormData();
    formdata.append("file", event.target.files[0]);

    fetch(process.env.REACT_APP_API_URI + '/v1/products/' + details.id + '/upload', {
      method: 'POST',
      headers: { 'Authorization': 'Bearer ' + authCtx.token },
      body: formdata,
      redirect: 'follow'
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if(data.status == "success") {
        drawerCtx.setPageUpdate({page: "products"});
        drawerCtx.closeDrawer();
      }
    }).catch(error => {
      console.log('error', error);
    });

  }

  console.log("additionalImgs", additionalImgs);

  const files = additionalImgs ? [...additionalImgs] : [];
  
  const uploadAdditionalImages = (e) => {
    e.preventDefault();

    const formdata = new FormData();
    formdata.append("files", e.target.files[0]);
    
    setDisabled(true);
    setIsLoading(true);

    fetch(process.env.REACT_APP_API_URI + '/v1/products/' + details.id + '/uploadAdditional', {
      method: 'POST',
      headers: { 'Authorization': 'Bearer ' + authCtx.token, },
      body: formdata,
      // body: JSON.stringify({ files: additionalImgs }),
      redirect: 'follow'
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if(data.status == "success") {
        setProductImages((prev) => [...prev, { id: data.imageId, image: data.imagePath }]);
        setIsLoading(false);
        setDisabled(false);
        // drawerCtx.setPageUpdate({page: "products"});
        // drawerCtx.closeDrawer();
      }
    }).catch(error => {
      console.log('error', error);
    });

  }

  console.log("productImages", productImages);
  
  const replaceAdditionalImage = (e, additionalImgId) => {
    e.preventDefault();

    const formdata = new FormData();
    formdata.append("files", e.target.files[0]);
    
    setDisabled(true);
    setIsLoading(true);

    fetch(process.env.REACT_APP_API_URI + '/v1/products/' + additionalImgId + '/updateAdditional', {
      method: 'PUT',
      headers: { 'Authorization': 'Bearer ' + authCtx.token, },
      body: formdata,
      // body: JSON.stringify({ files: additionalImgs }),
      redirect: 'follow'
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if(data.status == "success") {
        setProductImages(productImages.map((productImg) => {
          if(productImg.id === additionalImgId) productImg.image = data.imagePath;
          return productImg;
        }));
        setIsLoading(false);
        setDisabled(false);
        // drawerCtx.setPageUpdate({page: "products"});
        // drawerCtx.closeDrawer();
      }
    }).catch(error => {
      console.log('error', error);
    });

  }
  
  const deleteAdditionalImage = (e, additionalImgId) => {
    e.preventDefault();
    
    setDisabled(true);
    setIsLoading(true);

    fetch(process.env.REACT_APP_API_URI + '/v1/products/' + additionalImgId + '/deleteAdditional', {
      method: 'DELETE',
      headers: { 'Authorization': 'Bearer ' + authCtx.token, },
      // body: formdata,
      // body: JSON.stringify({ files: additionalImgs }),
      redirect: 'follow'
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if(data.status == "success") {
        setProductImages(productImages.filter(img => img.id != additionalImgId));
        setIsLoading(false);
        setDisabled(false);
        // drawerCtx.setPageUpdate({page: "products"});
        // drawerCtx.closeDrawer();
      }
    }).catch(error => {
      console.log('error', error);
    });

  }

  console.log(additionalImgsFilelist?.length);

  // const onFileChangeCapture = (event) => {
  //   console.log(isZip);
  //   /*Selected files data can be collected here.*/
  //   console.log(event.target.files[0].type);
  //   if(isZip.isTrue && event.target.files[0].type != "application/x-zip-compressed") {
  //     alert("You did not upload a ZIP file... Please try again");
  //   } else if(isZip.isTrue && event.target.files[0].type == "application/x-zip-compressed") {
  //     console.log("Upload zip file");
  //   } else if(!isZip.isTrue && event.target.files[0].type != "application/xml") {
  //     console.log("You did not upload an XML file... Please try again");
  //   } else if(!isZip.isTrue && event.target.files[0].type == "application/xml") {
  //     console.log("You did not upload an XML file... Please try again");
  //   } else {
  //     alert("Something went wrong");
  //   }
  // };  


  return (
    <div className="container">
      <div className="drawer">

        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Upload Product Image { isLoading ? <CircularProgress size={25} /> : null }</h1>
        </header>

        { !isLoading ? <section>


          <div className="product-images">
            {/* <div className='header'>Display Image</div> */}
            <h2 style={{ margin: "0 0 15px" }}>Display Image</h2>
            <div className="display-image">
              <img src={details.image} height="350px" width="auto" alt="display-image" />
            </div>
          </div>

          <div className="double-column-form">
            <Button variant="contained" component="label" disabled={disabled}>
              {(details.image ? "Replace" : "Add") + " Display Image"}
              {/* <input type="file" onChangeCapture={onFileChangeCapture} hidden /> */}
              <input type="file" onChangeCapture={(e) => uploadImage(e)} hidden />
            </Button>
          </div>

          <br/>

          <div className="product-images">
            {/* <div className='header'>Additional Images</div> */}
            <h2 style={{ margin: "15px 0 15px" }}>Additional Images</h2>
            <div className="flex card-container">
              {productImages.map(img => {
                return <div className="card" style={{width: "180px"}}>
                  <div className="img">
                    <img src={img.image} style={{width: "180px", height: "180px"}} alt="" />
                  </div>
                  <div className="flex justify-center">
                    <Button variant="contained" component="label" disabled={disabled} 
                      // onClick={(e) => replaceAdditionalImage(e)}
                    >
                      Change
                      <input type="file" accept=".png, .jpg, .jpeg, .bmp" onChangeCapture={(e) => replaceAdditionalImage(e, img.id)} hidden />
                    </Button>
                    <Button className='btn remove-btn'variant="contained" component="label" disabled={disabled} onClick={(e) => deleteAdditionalImage(e, img.id)}>Remove</Button>
                  </div>
                </div>
              })}

              <div className="card">
                <Button className="img-upload-btn" variant="contained" component="label" disabled={disabled}>
                  <Add style={{ height: "136px", width: "136px", color: "#757575" }} />
                  {/* <input type="file" multiple accept=".png, .jpg, .jpeg, .bmp" onChange={(e) => {
                    const filelist = e.target.files;
                    setAdditionalImgsFilelist(filelist);
                  }} hidden /> */}
                  <input type="file" accept=".png, .jpg, .jpeg, .bmp" onChangeCapture={(e) => uploadAdditionalImages(e)} hidden />
                </Button>
              </div>
              {/* {additionalImgs.map((img, idx) => {
                return <div key={idx} className="card">
                  <div className="img">
                    <img src={URL.createObjectURL(img)} alt="" />
                  </div>
                  <div className="flex justify-center">
                    <Button className='btn change-btn' variant='outlined'>Change</Button>
                    <Button className='btn remove-btn' variant='outlined'>Remove</Button>
                  </div>
                </div>
              })} */}
            </div>
          </div>

          

          <div className="double-column-form">
            {/* <Button variant="contained" component="label" disabled={disabled}>
              Add Additional Images
              <input type="file" multiple accept=".png, .jpg, .jpeg, .bmp" onChange={(e) => setAdditionalImgs(Array.from(e.target.files))} hidden />
            </Button> */}
            
            
            {/* <Button className="add-img-upload-btn" variant="contained" component="label" disabled={additionalImgsFilelist?.length ? false : true}>
              Upload Additional Images
              <input type="file" accept=".png, .jpg, .jpeg, .bmp" onChangeCapture={(e) => {
              // const filelist = e.target.files[0];
              // setAdditionalImgsFilelist(filelist);
              uploadAdditionalImages(e);
            }} hidden />
            </Button> */}


          </div>
          <br/>
          

          {/* <div className="double-column-form aligned-right margin-top">
            
            <Button variant="contained" component="label" disabled={checkBtn() || disabled}>
              Create Product
              <input type="file" onClick={(e) => createProduct(e)} hidden />
            </Button>
          </div> */}

        </section> : null }
        
      </div>
    </div>
  );

}
export default UploadProductImageDrawer;

