import React from 'react';
import '../App.scss';
import { Typography, Paper, CardContent, Grid, TextField, Button } from '@material-ui/core'
import { SyntheticEvent, useState } from 'react';
import { Redirect } from 'react-router-dom';


const Overview = () => {

    // const [firstName, setfName] = useState('');
    // const [lastName, setlName] = useState('');
    // const [emailOrPhone, setEmailOrPhone] = useState('');
    // const [password, setPassword] = useState('');
    // const [cPassword, setcPassword] = useState('');
    // const [redirect, setRedirect] = useState(false);

    // const submit = async (e: SyntheticEvent) => {
    //     e.preventDefault();

    //     await fetch('http://localhost:3001/v1/register', {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify({
    //             firstName,
    //             lastName,
    //             emailOrPhone,
    //             password,
    //             cPassword,
    //         })
    //     });

    //     setRedirect(true);
    // }

    // if (redirect) {
    //     return <Redirect to="/login" />;
    // }







    // const paperStyle = { padding: 20, height: '70vh', width: 580, margin: "20px auto" }
    return (
        <h1>Overview</h1>
    );
}
export default Overview;