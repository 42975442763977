import React from 'react';

import RetailersTable from "../Components/RetailersTable";
import '../App.scss';
// import { AnimationOnScroll } from 'react-animation-on-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
AOS.init();


// import '../App.scss';

const Retailers = () => {

	return (
		<div className="container">
			<div data-aos="fade-up">
				<h1>Retailers</h1>
			</div>
			<RetailersTable />
		</div>
	);

}
export default Retailers;