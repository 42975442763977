import React, { useEffect, useState, useContext } from 'react';
import Image from "./Components/Image";
import SideBar from "./Components/SideBar";

// Pages
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Overview from "./Pages/Overview";
import Users from "./Pages/Users";
import Coupons from "./Pages/Coupons";
// import Clients from "./Pages/Clients";
import Products from "./Pages/Products";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import AuthContext from './store/AuthContext.js';
import { DrawerContextProvider } from './store/DrawerContext.js';
import { ModalContextProvider } from './store/ModalContext.js';

import DetailsDrawer from "./Components/DetailsDrawer";
import DetailsModal from "./Components/DetailsModal";
import Orders from './Pages/Orders';
import Retailers from "./Pages/Retailers.jsx";
import Customers from "./Pages/Customers.jsx";

function App() {
	
	const authCtx 								= useContext(AuthContext);
	const [name, setName] 				= useState('');
	console.log(authCtx);

	return (
		<div className="App">			
			<ModalContextProvider>
				<DrawerContextProvider>
					<BrowserRouter>
					{ authCtx.isLoggedIn ? <SideBar /> : null }
						<main className="main">						
							<Routes>	
								<Route path="/" exact index element={!authCtx.isLoggedIn ? <Login setName={setName} /> : <Navigate to='/overview' />} />
								<Route path="/login" exact index element={!authCtx.isLoggedIn ? <Login setName={setName} /> : <Navigate to='/overview' />} />
								<Route path="/register" exact element={!authCtx.isLoggedIn ? <Register/> : <Navigate to='/login' />} />
								<Route path="/overview" exact element={authCtx.isLoggedIn ? <Overview/> : <Navigate to='/login' />} />
								{/* <Route path="/orders" exact element={authCtx.isLoggedIn ? <Clients/> : <Navigate to='/login'/>} /> */}
								<Route path="/orders" exact element={authCtx.isLoggedIn ? <Orders/> : <Navigate to='/login'/>} />
								<Route path="/products" exact element={authCtx.isLoggedIn ? <Products/> : <Navigate to='/login'/>} />
								<Route path="/retailers" exact element={authCtx.isLoggedIn ? <Retailers/> : <Navigate to='/login'/>} />
								<Route path="/customers" exact element={authCtx.isLoggedIn ? <Customers/> : <Navigate to='/login'/>} />
								<Route path="/coupons" exact element={authCtx.isLoggedIn ? <Coupons/> : <Navigate to='/login'/>} />

							</Routes>
						</main>
						<DetailsDrawer />
						<DetailsModal />
					</BrowserRouter>
				</DrawerContextProvider>
			</ModalContextProvider>			
		</div>
	);
}

export default App;
