import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';

import { TextField, MenuItem, FormControl, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';



const CustomerDetailsDrawer = props => {

  console.log("customerDetailsDrawer", props?.customerDetails);
  const customerDetails = props?.customerDetails?.customerDetails;
  console.log(customerDetails);

  const [isLoading, setIsLoading] = useState(true);

  return (<>

    <div className="container">
      <div className="drawer">


        <div className="order-details">
          <h1>Customer Details: {customerDetails.customer}</h1>
          <div className="customer-info">
            <p>Name: {customerDetails.customer}</p>
          </div>
        </div>
      </div>
    </div>


  </>);


  // const [products, setProducts] = useState([]);


}

export default CustomerDetailsDrawer;
