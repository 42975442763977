import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { InputLabel, Paper, IconButton, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TextField, MenuItem, FormControl, Button } from '@mui/material';
import AuthContext from '../../store/AuthContext';
import CustomTable from '../CustomTable';
import { Delete, Done, Edit, NotInterestedOutlined } from '@material-ui/icons';

const OrderDetailsDrawer = props => {

  // console.log("orderDetailsDrawer", props?.orderDetails);
  const orderDetails = props?.orderDetails?.orderDetails;
  const order = props?.orderDetails?.orderDetails?.order;
  const address = props?.orderDetails?.orderDetails?.order?.address;
  const customer = props?.orderDetails?.orderDetails?.order?.customer;
  const productsArr = props?.orderDetails?.orderDetails?.order?.products;

  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [products, setProducts] = useState([]);
  const [lineItems, setLineItems] = useState(productsArr);
  const [newProduct, setNewProduct] = useState("");
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    
    fetch(process.env.REACT_APP_API_URI + '/v1/products', {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status === "success") {
        setProducts(data.products);        
        updateTotals();
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
        console.error("products request error");
      }
    }).catch(err => console.error("products request error", err));

  }, []);

  const updateTotals = () => {
    
    let subTotalAmount = 0;
    lineItems.forEach(item => {
      subTotalAmount = subTotalAmount + item.total;
    });
    setSubTotal(subTotalAmount);

    let newTotal = (Math.round(subTotalAmount * 118) / 100).toFixed(2);
    setTotal(newTotal);

  }
  
  const newProductLine = (e) => {

    let payload = {
      orderId: orderDetails.id,
      productId: e.target.value
    }
    console.log(payload);
    
    fetch(process.env.REACT_APP_API_URI + '/v1/orders/' + orderDetails.id + '/newLine', {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status === "success") {
        setLineItems([...lineItems, data.newLine]);
        updateTotals();
      } else {
        console.log("products request error");
      }
    }).catch(err => console.error("products request error", err));

  }

  const updateProductLine = (id, e) => {
    let newLines = [];
    lineItems.forEach((line, i) => {
      if(line.id == id) {
        let product = products.find(function(obj) { return obj.id == e.target.value; });
        line.product_id = e.target.value;
        line.title = product.title;
        line.variant = product.variant;
			}
			newLines.push(line);
    });
    setLineItems(newLines);
  }

  const updateQuantity = (id, e) => {
    let newLines = [];
    lineItems.forEach((line, i) => {
      if(line.id == id) {
        line.quantity = e.target.value;
        line.total = line.quantity * line.price;
			}
			newLines.push(line);
    });
    console.log(newLines);
    setLineItems(newLines);
    updateTotals();
  }

  const updatePrice = (id, e) => {
    let newLines = [];
    lineItems.forEach((line, i) => {
      if(line.id == id) {
        line.price = e.target.value;
        line.total = line.quantity * line.price;
			}
			newLines.push(line);
    });
    console.log(newLines);
    setLineItems(newLines);
    updateTotals();
  }

  const deleteLine = (id) => {
    console.log(id);

    setLineItems(lineItems.filter((lineItem) => {
      return lineItem.id !== id
    }));

    fetch(process.env.REACT_APP_API_URI + '/v1/orders/' + orderDetails.id + '/items/delete/' + id, {
      method: 'DELETE',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status === "success") {
        // setLineItems([...lineItems, data.newLine]);
      } else {
        console.log("products request error");
      }
    }).catch(err => console.error("products request error", err));
  }

  return (
    <>
      <div className="container">      
        <div className="drawer">
        
          <header>
            <h1>Order No: {orderDetails.id?.toString()?.padStart(4, "0")}</h1>
            <p><strong>Date:</strong> {orderDetails.date.split("T")[0] + " " + orderDetails.date.split("T")[1]}</p>
          </header>

          <div className="order-details">
          
            <div className="address-section">

              <div className="customer-info">
                <p className="customer-name">
                  {orderDetails.customer}&nbsp;
                  <a href="#">Change</a>
                </p>
                {/* &nbsp;<Button className="change-btn black-btn">Change</Button></p> */}
                {address && <>
                  <p>{address.address_line1 || ""}</p>
                  <p>{address.address_line2 || ""}</p>
                  <p>{address.pin_code}, {address.state}</p>
                </>}
                <p>Contact: {customer?.phone}</p>
                <a href="#">Change Address</a>
                {/* <Button className="change-btn black-btn">Change Adress</Button> */}
              </div>

              <div className="shop-info">
                <p className="shop-name">Khushbu</p>
                <p>27A, Zamrudpur, Greater Kailash I</p>
                <p>New Delhi, India - 110048</p>
                <p>GST: GSTNUM</p>
              </div>
            </div>

            <TableContainer component={Paper} className="products-table-container">
              <Table sx={{ minWidth: 350 }} aria-label="products table">
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell align="center">Quantity</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Subtotal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lineItems?.map(items => {
                    return (
                      <TableRow key={items.id}>
                        <TableCell style={isEditing ? {padding: "2px 8px"} : null}>
                          {
                            isEditing ? <>
                              <IconButton aria-label="delete" onClick={(e) => { deleteLine(items.id) }}>
                                <Delete />
                              </IconButton>
                              <Select value={items.product_id} inputProps={{ 'aria-label': 'Without label' }} onChange={e => updateProductLine(items.id, e)}>
                                {products?.map(product => {
                                  return (<MenuItem value={product.id} key={product.id}>{product.title} - {product.variant}</MenuItem>);
                                })}
                              </Select>
                            </> : <>{items.title} - {items.variant}</>
                          }
                        </TableCell>
                        <TableCell align="center" className="order-quantity" style={isEditing ? {padding: "2px 8px"} : null}>
                          { isEditing ? <TextField hiddenLabel type="number" placeholder="Quantity" value={items.quantity} onChange={(e) => updateQuantity(items.id, e)} fullWidth variant="outlined" size="normal" InputProps={{ disableUnderline: true }} /> : <>{items.quantity}</> } 
                        </TableCell>
                        <TableCell align="right" className="order-price" style={isEditing ? {padding: "2px 8px"} : null}>
                          { isEditing ? <TextField hiddenLabel type="number" placeholder="Price" value={items.price} onChange={(e) => updatePrice(items.id, e)} fullWidth variant="outlined" size="normal" InputProps={{ disableUnderline: true }} /> : <>{items.price}</> }                        
                        </TableCell>
                        <TableCell align="right" className="order-total">{items.total}</TableCell>
                      </TableRow>
                    );
                  })}
                  { 
                    isEditing ? <TableRow>
                      <TableCell style={{padding: "2px 8px"}}>
                        <Select value={0} inputProps={{ 'aria-label': 'Without label' }} onChange={e => newProductLine(e)}>
                          <MenuItem value={0} key={0}>Add new product</MenuItem>
                          {products?.map(product => {
                            return (<MenuItem value={product.id} key={product.id}>{product.title} - {product.variant}</MenuItem>);
                          })}
                        </Select>
                      </TableCell>
                      <TableCell align="center" className="order-quantity" style={isEditing ? {padding: "2px 8px"} : null}>&nbsp;</TableCell>
                      <TableCell align="right" className="order-price" style={isEditing ? {padding: "2px 8px"} : null}>&nbsp;</TableCell>
                      <TableCell align="right" className="order-total">&nbsp;</TableCell>
                    </TableRow> : null
                  }
                </TableBody>
              </Table>
            </TableContainer>

            <div className="totals">
              <div className="total-row">
                <div className="spacer"></div>
                <div className="description">Sub Total</div>
                <div className="amount">{subTotal}</div>
              </div>
              <div className="total-row">
                <div className="spacer"></div>
                <div className="description">GST</div>
                <div className="amount">32</div>
              </div>
              <div className="total-row">
                <div className="spacer"></div>
                <div className="description">Shipping</div>
                <div className="amount">32</div>
              </div>
              <div className="total-row total">
                <div className="spacer"></div>
                <div className="description"><strong>TOTAL</strong></div>
                <div className="amount"><strong>{total}</strong></div>
              </div>
            </div>

            {/* {editingOrder && <AddNewProduct />} */}

            {/* <CustomTable orderItems={productsArr} products={products} /> */}

            <div className="order-btns">
              <Button className="confirm-btn black-btn">Confirm Order</Button>
              <div className="spacer"></div>
              { isEditing ? <Button className="edit-products-btn cancel-btn" onClick={() => setIsEditing(!isEditing)}>Cancel</Button> : null }
              <Button className="edit-products-btn black-btn" onClick={() => setIsEditing(!isEditing)}>{ isEditing ? <>Save Changes</> : <>Edit Products</> }</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );


  // const [products, setProducts] = useState([]);


}

export default OrderDetailsDrawer;
