import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';

import { TextField, Select, MenuItem, FormControl, InputLabel, Button, Checkbox, FormControlLabel, RadioGroup, Radio, FormLabel } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import AuthContext from '../../store/AuthContext.js';
import DrawerContext from '../../store/DrawerContext.js';
import { Percent } from '@mui/icons-material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function DeleteCouponDrawer(props) {

  const authCtx = useContext(AuthContext);	
  const drawerCtx = useContext(DrawerContext);

  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  let data = props.couponDetails.orderDetails;
  console.log("props", data)

  const [radio, setRadio] = useState(data.type);
  const [radio2, setRadio2] = useState(data.auto_apply);
  const [radioAllow, setRadioAllow] = useState(data.usage);
  const [users, setUsers] = useState([{label: "Test", id: 1}, {label: "Dummy", id: 2}, {label: "John Doe", id:3}]);
  const [autosel, setAuto] = useState(users[data.customer_id]);
  const [coupon, setCoupon] = useState(data.customer);
  const [desc, setDesc] = useState(data.desc);
  const [discount, setDiscount] = useState(data.total);
  const [date, setDate] = useState(data.valid.trim().split(/\s+/)[0]);
  const [type, setType] = useState(data.discount_type);

  const [parentProduct, setParentProduct] = useState(-1);
  const [parentProducts, setParentProducts] = useState([]);
  const [showForm, setShowForm] = useState("");

  const [productTitle, setProductTitle] = useState("");
  const [productVariant, setProductVariant] = useState("");
  const [productPrice, setProductPrice] = useState(0.00);
  const [productDescription, setProductDescription] = useState("");

  const updateBaseProduct = (parent) => {
    setParentProduct(parent);
    if(parent == 0) {
      setShowForm("parent");
    } else if(parent == -1) {
      setShowForm("");
    } else {
      setShowForm("variant");
    }
  }

  useEffect(() => {
    // fetch(process.env.REACT_APP_API_URI + '/v1/products/byParent', {
    //   method: 'GET',
    //   mode: 'cors',
    //   headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    // }).then(response => {
    //   return response.json();
    // }).then(data => {
		// 	console.log(data);
    //   if(data.status == "success") {
    //     setParentProducts(data.products);
    //     setIsLoading(false);
    //   } else {
    //     // setOpenSnackbar(true);
    //   }
    // });
    setIsLoading(false);
	}, []);


  const checkBtn = () => {
    if(coupon != "" && desc != "" && date != "" && discount != "" && radioAllow != "") {
      return false;
    } else {
      return true;
    }
  }

  const createCoupon = (event) => {
    event.preventDefault();

    setDisabled(true);
    setIsLoading(true);
  

    let jsonBody        = JSON.stringify({
      customer_id: data.customer_id,
      coupon_type: radio,
      coupon_code: coupon,
      description: desc,
      auto_apply: radio2,
      valid_till: date,
      discount_type: type,
      discount: discount,
      usage_type: radioAllow,
      claimed: data.claimed
    });

    fetch(process.env.REACT_APP_API_URI + '/v1/coupons/' + data.id, {
      method: 'DELETE',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    //   body: jsonBody,
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        drawerCtx.closeDrawer();
        drawerCtx.setPageUpdate({page: "coupons"});
      } else {
        setDisabled(false);
        setIsLoading(false);
        console.log(data)
      }
    });

  }

  const renderOptional = (radio) => {
    switch(radio) {
      case 'sitewide':
        return <></>;
      case 'user':
        return <>
          {/* <div className="double-column-form">
            <TextField label="Specification" value={productVariant} onChange={(e) => {setProductVariant(e.target.value)}} placeholder="Specification" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
            <TextField label="Price" value={productPrice} onChange={(e) => {setProductPrice(e.target.value)}} placeholder="Price" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          </div>
          <br/>
          <div className="double-column-form">
            <TextField multiline rows={4} label="Description" value={productDescription} onChange={(e) => {setProductDescription(e.target.value)}} placeholder="Description" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          </div> */}
          <Autocomplete
            value={autosel}
            onChange={(event, newValue) => {
              setAuto(newValue.id);
            }}
            id="cntrl"
            options={users}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Specify User" />}
          />
          <br/>
        </>;
      default:
        return null;
    }
  }
  

  return (
    <div className="container">
      <div className="drawer">

        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Delete Coupon { isLoading ? <CircularProgress size={25} /> : null }</h1>
        </header>

        { !isLoading ? <section>

          <div>
            Are you sure you want to delete coupon: {coupon}
          </div>

          <div className="double-column-form aligned-middle margin-top">
            
            <Button variant="contained" component="label" disabled={false || disabled}>
              Delete Coupon
              <input type="file" onClick={(e) => createCoupon(e)} hidden />
            </Button>
          </div>

        </section> : null }
        
      </div>
    </div>
  );

}
export default DeleteCouponDrawer;