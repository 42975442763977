import { useContext, useState, useEffect } from 'react';
import AOS from 'aos';

import { DataGrid } from '@mui/x-data-grid';
import { Link } from '@material-ui/core';

import AuthContext from '../store/AuthContext.js';
import DrawerContext from '../store/DrawerContext.js';
import ModalContext from '../store/ModalContext.js';

// import ClientsTable from "../Components/ClientsTable";

// ..
AOS.init();

const Orders = () => {
	
	
	const authCtx = useContext(AuthContext);	
  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);

	const [orders, setOrders] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {

		setIsLoading(true);
		async function fetchData() {
			try {
				const response = await fetch(process.env.REACT_APP_API_URI + '/v1/orders', { 
					method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
				});
				const json = await response.json();
				console.log("jsondata", json.data);
				setOrders(json.data);
				setIsLoading(false);
			} catch (e) {
				console.error(e);
			}
		}
		fetchData();

	}, []);
  
	function toggleDrawerHandler(drawer, user) {
		drawerCtx.openDrawer();
		drawerCtx.setDetails(drawer, user);
	};

	function toggleModalHandler(modal, user) {
		modalCtx.openModal();
		modalCtx.setDetails(modal, user);
	};

	const getDateISO = (createdAt) => new Date(createdAt.replace(/ /g,"T")).toISOString();

	const getDateString = (dateISOString) => {
    const dateArr = new Date(dateISOString)?.toDateString()?.split(" ")?.slice(1, 4);
    const dateStr = dateArr[0] + " " + dateArr[1] + ", " + dateArr[2].slice(2, 4);
    return dateStr || "";
  }
	
	const columns = [
		{ field: 'id', headerName: 'ID', width: 100, headerAlign: "center", align: "center", renderCell: (params) => (<Link className="order-details-link" onClick={() => {
			// console.log("order params.row\n", params.row);
			toggleDrawerHandler('order-details', { orderDetails: params?.row });
		}}>{params.value?.toString()?.padStart(4, "0") || params.value}</Link>) },
		// { renderCell: (params) => <Link to ></Link>, headerName: 'ID', width: 70 },
		{ field: 'date', headerName: 'DATE', width: 180, headerAlign: "center", align: "center", renderCell: ({ row }) => getDateString(row.date)},
		{ field: 'customer', headerName: 'CUSTOMER', width: 200, renderCell: (params) => (<Link className="customer-details-link" onClick={() => {
			toggleDrawerHandler('customer-details', { customerDetails: params?.row });
		}}>{params.value}</Link>) },
		{ field: 'status', headerName: 'STATUS', width: 100 },
		{ field: 'type', headerName: 'TYPE', width: 100 },
		{ field: 'source', headerName: 'SOURCE', width: 150 },
		{ field: 'total', headerName: 'TOTAL', width: 150 },
	];

	const rowsData = orders?.map(order => {
		return order.id && ({ id: order.id, date: getDateISO(order.customer?.created_at), customer: order.address?.contact_name || order.customer?.full_name, total: order.total, order: order });
	})

	console.log("rowsData", rowsData);
  

	return (
		<div className="container">
			<header>
				<div data-aos="fade-up">
					<h1>Orders</h1>
				</div>
				<div data-aos="fade-up" data-aos-delay="400">
					<a className="header-cta" onClick={() => {toggleModalHandler('create-order', null)}}>Create</a>
				</div>
			</header>			
			{/* <ClientsTable /> */}
			<div data-aos="fade-up" style={{ height: 500, width: '100%' }}>
      	<DataGrid sx={{ boxShadow: 2, border: 1, borderColor: '#77777777',
					'& .MuiDataGrid-cell:hover': { color: 'primary.main', }, }} 
					rows={rowsData} columns={columns} />
    </div>
		</div>
	);

}
export default Orders;
