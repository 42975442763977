import react, { useState, useContext } from 'react';
import { useNavigate, Link } from "react-router-dom";

import { Paper, Grid, TextField, CardContent, Button, InputAdornment, FormControlLabel } from '@material-ui/core';
import { Checkbox, Snackbar } from '@material-ui/core';
import { Person, Email, Lock } from '@mui/icons-material/';

import '../App.scss';

import AuthContext from '../store/AuthContext.js';

import Welcome from '../assets/img/login/welcome.png';



const Login = (props) => {

	let navigate        					= useNavigate();
	const authCtx 								= useContext(AuthContext);
			
	const [email, setEmail] 			= useState('');
	const [password, setPassword] = useState('');
	const [redirect, setRedirect] = useState(false);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("Sorry, email or password incorrect");

	function submit() {

		fetch(process.env.REACT_APP_API_URI + '/v1/adminlogin', {
			method: 'POST',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				email,
				password
			})
		}).then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			if(data.status == "success") {
				const tokenExpiration 	= new Date(new Date().getTime() + 86400000);
				authCtx.login(data.data.token, data.data.userId, data.data.userName, data.data.userEmail, data.data.userScope, tokenExpiration);
				navigate("/overview", { replace: true });
			} else {
				setSnackbarMessage(data.message);
				setOpenSnackbar(true);
			}
		});

	}

	function changeEmail(event) {
		setEmail(event.target.value);
	}

	function changePassword(event) {
		setPassword(event.target.value);
	}

	const paperStyle = { padding: 20, width: 580, margin: "0 auto" }
		return (
			<div className="login-register">

				<div className="login">

					<Paper elevation={2} style={paperStyle}>
						<CardContent>
							<div className="form-h1"><Person sx={{fontSize: 60}}/></div>
							<div className="form-h1">Hello Again!!</div>
							<div className="form-heading">Welcome back to your account login to your account to get started</div>

							<div className="form-wrapper">
								<form>
									<Grid container spacing={4}>

										<Grid xs={12} item>
											<TextField label="Email Address" onChange={changeEmail} value={email} placeholder="Email Address" variant="outlined" InputProps={{endAdornment: <InputAdornment position="start"><Email sx={{color: '#888'}} /></InputAdornment>,}} fullWidth required />
										</Grid>

										<Grid xs={12} item>
											<TextField label="Password" onChange={changePassword} value={password} type="password" placeholder="Password" variant="outlined" InputProps={{endAdornment: <InputAdornment position="start"><Lock sx={{color: '#888'}} /></InputAdornment>,}} fullWidth required />
										</Grid>

									</Grid>

									<div className="remember-forgot-wrapper">
										 <div className="circle-checkbox">
											<FormControlLabel control={<Checkbox name="checked8" color="primary" />} label="Remember Me" />
										</div> 
										<div className="forgot-password">Forgot Password ?</div>
									</div>
									<Button className="outlineBtn btn-large" onClick={submit}>Login</Button>
									<div className="no-account-wrapper">
										Don't have an account?
										<Link to="/register"> SIGN UP</Link>
									</div>
								</form>
							</div>
							<Snackbar open={openSnackbar} autoHideDuration={1000} message={snackbarMessage}></Snackbar>
							

						</CardContent>
					</Paper>

				</div>
			</div>
		);
	}

export default Login;









