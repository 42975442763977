import React, { useContext } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'; 

import DrawerContext from '../store/DrawerContext.js';

import ProductsGrid from "../Components/ProductsGrid";

// ..
AOS.init();


// import '../App.scss';

const Products = () => {

	const drawerCtx = useContext(DrawerContext);	

	function toggleDrawerHandler(drawer, user) {
		drawerCtx.openDrawer();
		drawerCtx.setDetails(drawer, user);
	};

	return (
		<div className="container">
			<header>
				<div data-aos="fade-up">
					<h1>Products</h1>
				</div>
				<div data-aos="fade-up" data-aos-delay="400">
					<a className="header-cta" onClick={() => {toggleDrawerHandler('create-product', null)}}>Create</a>
				</div>
			</header>
			<ProductsGrid />			
		</div>
	);

}
export default Products;