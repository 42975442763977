import React, { useState, useContext, useEffect, Component  } from 'react';
import { useLocation } from 'react-router-dom';

import { Drawer } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import { classes } from 'istanbul-lib-coverage';

import UserDrawer from './Drawers/UsersDrawer';
import CreateProductDrawer from './Drawers/CreateProductDrawer';
import UploadProductImageDrawer from './Drawers/UploadProductImageDrawer';

import DrawerContext from '../store/DrawerContext.js';
import EditProductDrawer from './Drawers/EditProductDrawer';
import OrderDetailsDrawer from './Drawers/OrderDetailsDrawer';
import CustomerDetailsDrawer from './Drawers/CustomerDetailsDrawer';
import CreateCouponDrawer from './Drawers/CreateCouponDrawer';
import EditCouponDrawer from './Drawers/EditCouponDrawer';
import DeleteCouponDrawer from './Drawers/DeleteCouponDrawer';

const noMenuRoutes = ["/login", "/register"];

function DetailsDrawer(props) {

	const drawerCtx = useContext(DrawerContext);
	const { pathname } = useLocation();

	const [openDrawer, setOpenDrawer] = useState(false);
	const [selectedTab, setSelectedTab] = React.useState(0);
	const handleChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	function closeDrawer() {
		drawerCtx.closeDrawer();
	}

	if (noMenuRoutes.some((item) => pathname.includes(item))) return null;

	const currentDrawer = () => {
		switch(drawerCtx.drawer) {
			
			// Users
			case "user-details": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><UserDrawer userDetails={drawerCtx.details} /></Drawer>;
			case "create-user": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><h1>Create New User</h1></Drawer>;
			case "create-product": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateProductDrawer /></Drawer>;
			case "product-image-upload": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><UploadProductImageDrawer details={drawerCtx.details} /></Drawer>;
			case "edit-product": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditProductDrawer productDetails={drawerCtx.details} /></Drawer>;
			case "order-details": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><OrderDetailsDrawer disableEnforceFocus orderDetails={drawerCtx.details} /></Drawer>;
			case "customer-details": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CustomerDetailsDrawer customerDetails={drawerCtx.details} /></Drawer>;
			case "create-coupon": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateCouponDrawer couponDetails={drawerCtx.details} /></Drawer>;
			case "edit-coupon": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditCouponDrawer couponDetails={drawerCtx.details} /></Drawer>;
			case "delete-coupon": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><DeleteCouponDrawer couponDetails={drawerCtx.details} /></Drawer>;
		

			default:  return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><h1>No details here, sorry!</h1></Drawer>;
		}
	}

	return (
		<div>{ currentDrawer() }</div>
	);

}
export default DetailsDrawer;
