import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { List } from '@material-ui/icons';

import UsersDrawer from './DetailsDrawer';
import DrawerContext from '../store/DrawerContext.js';

import '../App.scss';
import AuthContext from '../store/AuthContext';

const useStyles = makeStyles({
	table: {
		minWidth: 650,
		color: "#747d8c"
	},
});


function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}

function showRegDate(date) {
	return date.substring(0, 10);
}

function showStatus(status) {
	if (status == 1) {
		return "active";
	}
	return "inactive";
}

const rows = [];

function RetailersTable(props) {

	const drawerCtx = useContext(DrawerContext);
  const authCtx = useContext(AuthContext);

	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(true);
	const [loadedUsers, setUsersData] = useState([]);
	const [retailersAddressesData, setRetailersAddressesData] = useState([]);
	const [state, setState] = React.useState(false);
	
	function toggleDrawerHandler(drawer, user) {
		drawerCtx.openDrawer();
		drawerCtx.setDetails(drawer, user);
	};

	useEffect(async () => {

		setIsLoading(true);
		fetch(process.env.REACT_APP_API_URI + '/v1/retailers', {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
		})
		.then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			setIsLoading(false);
			setUsersData(data.details);
		});

		fetch(process.env.REACT_APP_API_URI + '/v1/retailers-addresses', {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
		})
		.then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			setIsLoading(false);
			setRetailersAddressesData(data.details);
		});

		// console.log(retailersAddressesData);
	}, []);
  
	useEffect(() => {
		
	}, []);
	

	if (isLoading) {
		return <section><CircularProgress /></section>
	}
  // console.log(loadedUsers);

	return (
		<>
			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="simple table" data-aos="fade-up">
					<TableHead>
						<TableRow>
							<TableCell align="center">Image</TableCell>
							<TableCell>Title</TableCell>
							<TableCell align="right">Locations</TableCell>
							<TableCell align="right">Contact Number</TableCell>
							<TableCell align="right">&nbsp;</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{loadedUsers.map((data) => (
							<TableRow key={data.id}>
								<TableCell align='center'><img src={data.logo} style={{minHeight: '25px'}} alt=""/></TableCell>
								<TableCell>{data.retailer}</TableCell>								
								<TableCell align="right">{data.phone}</TableCell>
								<TableCell align="right">{data.address_count}</TableCell>
								{/* <TableCell align="right">{showRegDate(data.registration_date)}</TableCell>
								<TableCell align="right">{showStatus(data.status)}</TableCell> */}
								<TableCell align="right"><MoreVertIcon className="table-tool" onClick={() => {toggleDrawerHandler('user-details', data)}} /></TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<UsersDrawer openTheDrawer={state}/>
		</>
	);
}

export default RetailersTable;