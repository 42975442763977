import React from 'react';

import UsersTable from "../Components/UsersTable";
import '../App.scss';
// import { AnimationOnScroll } from 'react-animation-on-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
AOS.init();


// import '../App.scss';

const Users = () => {

	return (
		<div className="container">
			<div data-aos="fade-up">
				<h1>Users</h1>
			</div>
			<UsersTable />
		</div>
	);

}
export default Users;