import { useState, useContext, useEffect } from 'react';
import { Modal, Box } from '@material-ui/core';

import ModalContext from '../store/ModalContext.js';

import CreateOrderModal from "./Modals/CreateOrderModal.jsx";

const DetailsModal = () => {

  const modalCtx = useContext(ModalContext);

  const boxStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const currentModal = () => {
		switch(modalCtx.modal) {
      case "create-order": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><CreateOrderModal details={modalCtx.details} /></Box></Modal>;
    }
	}

  return (
    <div>{ currentModal() }</div>
  );

}

export default DetailsModal;
